import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  IQuestionAnswerAPI,
  questionTypes,
} from "../components/questionnaires/questionnaire-builder/QuestionnarieBuilderOptions";
import {
  questionnariePatientAnswer,
  questionnarieUpdatePatientAnswerByAdmin,
} from "../services/api/QuestionnarieApi";
import {
  eligibilityQuestionSetID,
  counsellingQuestionSetID,
  postLARCQuestionSetID,
  appointmentQuestionSetID,
  appointmentRebookQuestionSetID,
} from "../utils/HelperFunctions";
import { createAuditTrail } from "../services/api/QuestionnarieApi";
import { UserTypes } from "../models/UserTypes";
// Import with aliases to avoid conflict
import { eligibilityQuestionIDs as topEligibilityQuestionIDs } from "../pages/top/questionIds";
import { eligibilityOptionsIDs as topEligibilityOptionIDs } from "../pages/top/questionIds";
import { eligibilityQuestionIDs as vasEligibilityQuestionIDs } from "../pages/vas/questionIds";
import { eligibilityOptionsIDs as vaseligibilityOptionsIDs } from "../pages/vas/questionIds";

interface StaticFormsState {
  data: null | object;
  status: string;
  error: string | null;
  cbsPhoneNumber: string | null;
}

const initialState: StaticFormsState = {
  data: null,
  status: "idle",
  error: null,
  cbsPhoneNumber: null,
};

enum QuestionSetSaveStatus {
  Draft = "draft",
  Hold = "hold",
  Completed = "completed",
  endFlow = "end flow",
}

const auditSaveFn = async (
  module: string,
  action: string,
  actionType: string,
  toUserID: string | null = null
) => {
  try {
    const auditParam: any = toUserID
      ? { module, actionType, action, userId: toUserID }
      : { module, actionType, action };
    await createAuditTrail(auditParam);
  } catch (error) {
    console.log("err", error);
  }
};

// Define createAsyncThunk for setting static forms data
export const setStaticFormsDataAsync = createAsyncThunk(
  "static_forms/setStaticFormsData",
  async (payload: any, thunkAPI) => {
    try {
      const { getState } = thunkAPI;

      // Accessing the Redux state to get usertype
      const state: any = getState();
      const usertype = state.userAuthReducer?.userInfo?.usertype;

      if (
        usertype !== UserTypes.superAdmin &&
        usertype !== UserTypes.admin &&
        payload?.status !== "completed" &&
        payload?.status !== QuestionSetSaveStatus.endFlow &&
        payload?.id
      ) {
        const answersPayload = await prepareAnswerPayload(
          payload,
          QuestionSetSaveStatus.Draft,
          usertype
        );
        if (answersPayload.answers.length)
          await questionnariePatientAnswer(answersPayload);
      }
      return payload;
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Failed to set static forms data";
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

// Define createAsyncThunk for submitting static forms data
export const endFlowStaticFormsDataAsync = createAsyncThunk(
  "static_forms/endFlowStaticFormsData",
  async (payload: any, thunkAPI) => {
    try {
      const { getState } = thunkAPI;

      // Accessing the Redux state to get usertype
      const state: any = getState();
      const usertype = state.userAuthReducer?.userInfo?.usertype;

      if (
        usertype !== UserTypes.superAdmin &&
        usertype !== UserTypes.admin &&
        payload?.status !== "completed" &&
        payload?.status !== QuestionSetSaveStatus.endFlow &&
        payload?.id
      ) {
        const answersPayload = await prepareAnswerPayload(
          payload,
          QuestionSetSaveStatus.endFlow,
          usertype
        );
        await questionnariePatientAnswer(answersPayload);
      } else if (
        (usertype === UserTypes.superAdmin || usertype === UserTypes.admin) &&
        payload?.status !== "completed" &&
        payload?.status !== QuestionSetSaveStatus.endFlow &&
        payload?.id
      ) {
        const answersPayload = await prepareAnswerPayload(
          payload,
          QuestionSetSaveStatus.endFlow,
          usertype
        );
        await questionnarieUpdatePatientAnswerByAdmin(answersPayload);
      }
      return payload;
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Failed to set static forms data";
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

// Define createAsyncThunk for submitting static forms data
export const submitStaticFormsDataAsync = createAsyncThunk(
  "static_forms/submitStaticFormsData",
  async (payload: any, thunkAPI) => {
    try {
      const { getState } = thunkAPI;

      // Accessing the Redux state to get usertype
      const state: any = getState();
      const usertype = state.userAuthReducer?.userInfo?.usertype;
      if (
        usertype !== UserTypes.superAdmin &&
        usertype !== UserTypes.admin &&
        payload?.status !== QuestionSetSaveStatus.endFlow &&
        payload?.id
      ) {
        const answersPayload = await prepareAnswerPayload(
          payload,
          QuestionSetSaveStatus.Completed,
          usertype
        );
        await questionnariePatientAnswer(answersPayload);
        if (answersPayload.status === QuestionSetSaveStatus.Completed) {
          if (eligibilityQuestionSetID.includes(answersPayload.questionSetId)) {
            auditSaveFn(
              "Client Form submission",
              "Let’s Get Started",
              `Submitted`
            );
          } else if (
            counsellingQuestionSetID.includes(answersPayload.questionSetId)
          ) {
            auditSaveFn("Client Form submission", "Counselling", `Submitted`);
          } else if (
            postLARCQuestionSetID.includes(answersPayload.questionSetId)
          ) {
            auditSaveFn("Client Form submission", "PostLARC", `Submitted`);
          } else if (
            appointmentQuestionSetID.includes(answersPayload.questionSetId)
          ) {
            auditSaveFn(
              "Client Form submission",
              "Your appointment",
              `Submitted`
            );
          } else if (
            appointmentRebookQuestionSetID.includes(
              answersPayload.questionSetId
            )
          ) {
            auditSaveFn(
              "Client Form submission",
              "Rebook Appointment",
              `Submitted`
            );
          }
        }
      } else if (
        (usertype === UserTypes.superAdmin || usertype === UserTypes.admin) &&
        payload?.status !== QuestionSetSaveStatus.endFlow &&
        payload?.id
      ) {
        const answersPayload = await prepareAnswerPayload(
          payload,
          QuestionSetSaveStatus.Completed,
          usertype
        );
        await questionnarieUpdatePatientAnswerByAdmin(answersPayload);
      }
      return payload;
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Failed to set static forms data";
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const staticFormsSlice = createSlice({
  name: "static_forms",
  initialState,
  reducers: {
    // Any synchronous reducers can go here if needed
    setDataToNull: (state) => {
      state.data = null;
    },
    setStaticFormsData: (state, action: PayloadAction<object>) => {
      state.data = action.payload;
    },
    endFlowStaticFormsData: (state, action: PayloadAction<object>) => {
      state.data = action.payload;
    },
    submitStaticFormsData: (state, action: PayloadAction<object>) => {
      state.data = action.payload;
    },
    setPhoneNumber: (state, action: PayloadAction<string>) => {
      state.cbsPhoneNumber = action.payload;
    },
    clearPhoneNumber: (state) => {
      state.cbsPhoneNumber = null;
    },
  },
  extraReducers: (builder) => {
    // Handle pending, fulfilled, and rejected actions for setStaticFormsDataAsync
    builder.addCase(setStaticFormsDataAsync.pending, (state: any) => {
      state.status = "loading";
    });
    builder.addCase(
      setStaticFormsDataAsync.fulfilled,
      (state: any, action: PayloadAction<object>) => {
        // Use PayloadAction<object> here
        state.status = "succeeded";
        state.data = action.payload;
      }
    );
    builder.addCase(
      setStaticFormsDataAsync.rejected,
      (state: any, action: PayloadAction<object | any>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to set static forms data";
      }
    );

    // Handle pending, fulfilled, and rejected actions for submitStaticFormsDataAsync
    builder.addCase(endFlowStaticFormsDataAsync.pending, (state: any) => {
      state.status = "loading";
    });
    builder.addCase(
      endFlowStaticFormsDataAsync.fulfilled,
      (state: any, action: PayloadAction<object>) => {
        // Use PayloadAction<object> here
        state.status = "succeeded";
        state.data = action.payload;
      }
    );
    builder.addCase(
      endFlowStaticFormsDataAsync.rejected,
      (state: any, action: PayloadAction<object | any>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to set static forms data";
      }
    );

    // Handle pending, fulfilled, and rejected actions for submitStaticFormsDataAsync
    builder.addCase(submitStaticFormsDataAsync.pending, (state: any) => {
      state.status = "loading";
    });
    builder.addCase(
      submitStaticFormsDataAsync.fulfilled,
      (state: any, action: PayloadAction<object>) => {
        // Use PayloadAction<object> here
        state.status = "succeeded";
        state.data = action.payload;
      }
    );
    builder.addCase(
      submitStaticFormsDataAsync.rejected,
      (state: any, action: PayloadAction<object | any>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to set static forms data";
      }
    );
  },
});

/**
 * Determines the status of a question set based on its preparation status and current status.
 * @param prepareStatus - The preparation status of the question set.
 * @param currentStatus - The current status of the question set.
 * @returns The determined status of the question set.
 */
const getQuestionSetStatus = (
  prepareStatus: QuestionSetSaveStatus,
  currentStatus: string
): string => {
  switch (true) {
    // If the current status is draft or empty and the preparation status is draft, set status to draft.
    case (currentStatus === QuestionSetSaveStatus.Draft ||
      currentStatus === "") &&
      prepareStatus === QuestionSetSaveStatus.Draft:
      return QuestionSetSaveStatus.Draft;

    case (currentStatus === QuestionSetSaveStatus.Draft ||
      currentStatus === "") &&
      prepareStatus === QuestionSetSaveStatus.endFlow:
      return QuestionSetSaveStatus.endFlow;

    // If the current status is hold and the preparation status is completed, set status to hold.
    case currentStatus === QuestionSetSaveStatus.Hold &&
      prepareStatus === QuestionSetSaveStatus.Completed:
      return QuestionSetSaveStatus.Hold;

    // If the preparation status is completed and the current status is not hold, set status to completed.
    case prepareStatus === QuestionSetSaveStatus.Completed &&
      currentStatus !== QuestionSetSaveStatus.Hold:
      return QuestionSetSaveStatus.Completed;

    // Default case: If none of the specific conditions match, set status to draft.
    default:
      return QuestionSetSaveStatus.Draft;
  }
};

/**
 * Determines the status of a question set based on its preparation status and current status for the super admin and admin flow.
 * @param prepareStatus - The preparation status of the question set.
 * @param currentStatus - The current status of the question set.
 * @returns The determined status of the question set.
 */
const getQuestionSetStatusForAdmin = (
  prepareStatus: QuestionSetSaveStatus,
  currentStatus: string
): string => {
  switch (true) {
    case (currentStatus === QuestionSetSaveStatus.Hold ||
      currentStatus === QuestionSetSaveStatus.Completed) &&
      prepareStatus === QuestionSetSaveStatus.endFlow:
      return QuestionSetSaveStatus.endFlow;

    // If the current status is hold and the preparation status is completed, set status to hold.
    case currentStatus === QuestionSetSaveStatus.Hold &&
      prepareStatus === QuestionSetSaveStatus.Completed:
      return QuestionSetSaveStatus.Completed;

    // If the preparation status is completed and the current status is not hold, set status to completed.
    case prepareStatus === QuestionSetSaveStatus.Completed &&
      currentStatus !== QuestionSetSaveStatus.Hold:
      return QuestionSetSaveStatus.Completed;

    // Default case: If none of the specific conditions match, set status to draft.
    default:
      return currentStatus;
  }
};

/**
 * Prepares the answer payload for submitting a question set based on user answers and preparation status.
 * @param userAnswer - The user's answers to the questions.
 * @param prepareStatus - The preparation status of the question set.
 * @returns The prepared answer payload for submission.
 */

const prepareAnswerPayload = async (
  userAnswer: any,
  prepareStatus: QuestionSetSaveStatus,
  usertype: string
) => {
  // Initialize an empty array to store answers
  const answer: any = [];
  // Process each question in the user's answers
  userAnswer?.QuestionSetIds?.questions.map((question: any) => {
    if (question.type === questionTypes.multiplechoice) {
      // Process multiple-choice questions
      question?.userAnswer?.map((ans: any) => {
        answer.push({
          answer: null,
          optionId: ans?.optionId || null,
          questionId: question.id,
        });
      });
    } else if (question.type === questionTypes.yesnoquestion) {
      answer.push({
        answer: null,
        optionId: question.userAnswer?.[0]?.optionId || null,
        questionId: question.id,
      });
    } else if (question.type === questionTypes.freetext) {
      const optionMap = new Map<string, string>([
        [
          topEligibilityQuestionIDs.ubrn_number,
          topEligibilityOptionIDs.ubrn_number,
        ],
        [
          topEligibilityQuestionIDs.ubrnPostcode,
          topEligibilityOptionIDs.unrn_passcode,
        ],
        [
          vasEligibilityQuestionIDs.ubrn_number,
          vaseligibilityOptionsIDs.ubrn_number,
        ],
        [vasEligibilityQuestionIDs.passcode, vaseligibilityOptionsIDs.passcode],
      ]);
      if (
        question.id === topEligibilityQuestionIDs.hope_house_code || 
        question.id === topEligibilityQuestionIDs.bluebell_suite_FPC_code
      ) {
        optionMap.set(question.id, question.options[0]?.id);
      }
      const optionId = optionMap.get(question.id);
      const userAnswerText = question.userAnswer?.[0]?.answer || null;
      if (optionId !== undefined && userAnswerText !== null) {
        answer.push({
          answer: userAnswerText,
          optionId: optionId,
          questionId: question.id,
        });
      } else {
        answer.push({
          answer: userAnswerText,
          questionId: question.id,
        });
      }
    } else {
      answer.push({
        answer: question.userAnswer?.[0]?.answer,
        optionId: question.userAnswer?.[0]?.optionId,
        questionId: question.id,
      });
    }
  });
  // Determine the status based on preparation status and userAnswer status
  let status;

  if (usertype === UserTypes.superAdmin || usertype === UserTypes.admin) {
    status = await getQuestionSetStatusForAdmin(
      prepareStatus,
      userAnswer.status
    );
  } else {
    status = await getQuestionSetStatus(prepareStatus, userAnswer.status);
  }
  // Construct the answer payload for submission
  const filteredArray =
    prepareStatus === QuestionSetSaveStatus.Draft
      ? answer.filter((item: any) => item.answer || item.optionId)
      : answer.filter((item: any) => item.answer || item.optionId);
  const answerPayload: IQuestionAnswerAPI = {
    shareId: userAnswer.id,
    questionSetId: userAnswer.questionSetId,
    status: status,
    answers: filteredArray,
  };
  return answerPayload;
};

export const {
  setStaticFormsData,
  submitStaticFormsData,
  endFlowStaticFormsData,
  setPhoneNumber,
  clearPhoneNumber,
} = staticFormsSlice.actions;

export default staticFormsSlice.reducer;
