import { UUIDv4 } from "uuid-v4-validator";
import { questionTypes } from "../../components/questionnaires/questionnaire-builder/QuestionnarieBuilderOptions";
export const vasEligibilityQuestSetID = "87841b20-9ef4-4053-8ed2-ff694a3a2b3f";

export const eligibilityQuestionIDs: any = {
  nhs_number: "e5e2ca0a-0c2d-4f80-bc1f-947efbc1dc49",
  date_of_birth: "afde34f6-424a-4910-91c8-a7d5cce3640d",
  GP_details: "faae3ab5-f335-450c-bc55-7c15e5c61653",
  practitioner_name: "0c0195da-79d2-4fea-aaa6-a2eeff1e97dd",
  street_name: "b553eb49-6df8-4ebc-8aab-a2e12194a608",
  Town_or_City: "6b96a837-2c85-44c3-8aaa-14dfd607636a",
  postcode: "6ea551c6-ba95-4a61-b0b6-d4730796d677",
  ubrn_number: "2c4b3596-1fb7-45eb-939f-14574f3526c7",
  passcode: "3471ef19-5ac5-400c-a36b-372c0344dc05",
  already_contacted_local_provider: "7d91f9a3-493d-471f-a195-50bb6e75c342",
  what_local_provider_said_to_you: "2b23fbfe-8e90-4cd9-94c6-8027ac501f84",
  why_not_contacted_them: "1a11f0b1-14c8-4dea-a57c-759d3f328933",
  new_phone_no: "313eaed3-ae1a-4fd2-8cfe-1ab54d4de81a",
  yes_call_me_now: "126c7c86-4e28-4152-a8bb-79b625975fe5",
  call_next_working_day: "23ea58d8-477f-4dfd-9bc6-bae3bf3cbe35",
  change_phone_no: "03c4ccac-f132-4529-ace2-3787c8493118",
  private_options: "6e8236af-2093-41a2-a3a2-68dfba57a198",
  delete_account: "5d0e1b6e-18f6-4cb6-b825-c3c8d4c65038",

  call_day: "1730daa4-e1b2-4016-9e1a-a2189d51601c",
  call_prefer: "594e5ff5-a46c-416c-b52c-e30c477cab4c",
  call_stage: "d52f657d-03b6-44eb-a9b7-bad0c1c3fe44",
  call_date: "7dd93e65-cb94-46f3-a5a6-1d7e7e8b9f84",
  call_tz: "28df589d-7e30-443f-9131-e5bb5bde4b2f",
  risk_of_harm: "787c5e81-06cd-4246-bff1-bd1e5f67f24a",
  harm_are_you_at_risk_of: "edec64ae-c8a8-4011-9e9a-4469082107b9",
  risk_of_other_option: "879c4476-7c38-4381-af88-a5b65f0dfb76",
  explain_more_about_your_risk: "49c3c3f5-a5d4-403b-85cb-0721954e7069",
  immediate_risk_of_harm: "9231b113-9605-4ef2-a0a2-901a67b3297e",
  harm_are_you_at_immediate_risk_of: "f19b54e7-13de-4aa3-9211-790700a21122",
  immediate_risk_of_other_option: "0f3af3f9-9af0-4225-af99-7bd39fe19d44",
  explain_more_about_your_immediate_risk:
    "26f74a20-2fa0-479a-99f1-87b08991fd40",
  how_is_it_safest_contact_to_ensure_your_wellbeing:
    "b304d215-3035-4cb8-8a53-d4a2f9fbb67f",
  need_to_contact_GP_happy_of_us_to_do: "d5d9d4a8-c8fe-49f0-ae5c-7c2f0888efc2",
};

export const riskOfHarmCheckBoxOptions = [
  {
    label: "Physical harm",
    optionId: "risk_of_harm_physical_harm",
  },
  {
    label: "Emotional abuse",
    optionId: "risk_of_harm_emotional_abuse",
  },
  {
    label: "Financial abuse",
    optionId: "risk_of_harm_financial_abuse",
  },
  {
    label: "Suicidal thoughts",
    optionId: "risk_of_harm_suicidal_thoughts",
  },
  {
    label: "Self-harm",
    optionId: "risk_of_harm_self_harm",
  },
  {
    label: "Homelessness",
    optionId: "risk_of_harm_homelessness",
  },
  {
    label: "Neglect (by self or carer)",
    optionId: "risk_of_harm_neglect",
  },
  {
    label: "Sexual assault",
    optionId: "risk_of_harm_sexual_assault",
  },
  {
    label: "Social services involvement",
    optionId: "risk_of_harm_social_services_involvement",
  },
  {
    label: "Other",
    optionId: "risk_of_harm_other",
  },
];

export const immediateRiskOfHarmCheckBoxOptions = [
  {
    label: "Physical harm",
    optionId: "immediate_risk_of_harm_physical_harm",
  },
  {
    label: "Emotional abuse",
    optionId: "immediate_risk_of_harm_emotional_abuse",
  },
  {
    label: "Financial abuse",
    optionId: "immediate_risk_of_harm_financial_abuse",
  },
  {
    label: "Suicidal thoughts",
    optionId: "immediate_risk_of_harm_suicidal_thoughts",
  },
  {
    label: "Self-harm",
    optionId: "immediate_risk_of_harm_self_harm",
  },
  {
    label: "Homelessness",
    optionId: "immediate_risk_of_harm_homelessness",
  },
  {
    label: "Neglect (by self or carer)",
    optionId: "immediate_risk_of_harm_neglect",
  },
  {
    label: "Sexual assault",
    optionId: "immediate_risk_of_harm_sexual_assault",
  },
  {
    label: "Social services involvement",
    optionId: "immediate_risk_of_harm_social_services_involvement",
  },
  {
    label: "Other",
    optionId: "immediate_risk_of_harm_other",
  },
];

export const eligibilityOptionsIDs: any = {
  risk_of_harm_yes: "cb1dd2b5-a6bd-4fb1-8dea-fbc52922dfe7",
  risk_of_harm_no: "bce32ec5-a9ac-45b9-bb2c-aad7c39d367a",
  risk_of_harm_physical_harm: "d27edda1-488f-4bdd-b1a9-f0d90619ca83",
  risk_of_harm_emotional_abuse: "fa86a6ab-2b71-42bf-9ea0-8dd4dd8efcb8",
  risk_of_harm_financial_abuse: "6779add8-3954-43e8-a669-abbf9d0409d1",
  risk_of_harm_suicidal_thoughts: "b9014cca-029a-4636-b704-8efa57d88a34",
  risk_of_harm_self_harm: "6b930317-b643-4171-b429-5918c675d21f",
  risk_of_harm_homelessness: "d8d15e60-eb2f-4b36-87ae-2fb3bb114f68",
  risk_of_harm_neglect: "2dbace3f-6930-4f1a-86e1-eaa4a38d92b4",
  risk_of_harm_sexual_assault: "82ea743f-c42e-47eb-bda4-4cf8381094ed",
  risk_of_harm_social_services_involvement:
    "5a31cdb6-1796-4d59-a24f-df9dd1b264f6",
  risk_of_harm_other: "3140a58b-56b6-42fe-a304-06c1472ce754",
  immediate_risk_of_harm_yes: "91298772-7528-4c1e-a31d-41b393e7d0d1",
  immediate_risk_of_harm_no: "581de80e-0fd1-467a-a48a-888835e001a1",
  immediate_risk_of_harm_physical_harm: "7185d800-b8d6-44b7-a17c-3eaeb604b89c",
  immediate_risk_of_harm_emotional_abuse:
    "abde6869-621e-455f-afe9-b66371e3f39f",
  immediate_risk_of_harm_financial_abuse:
    "eb35161f-378e-4a7d-936d-9a09f756ed84",
  immediate_risk_of_harm_suicidal_thoughts:
    "a4d91dcf-9411-4696-9955-405f7962d3ed",
  immediate_risk_of_harm_self_harm: "ce788bb9-abd5-4883-bc1e-330e6e8d324e",
  immediate_risk_of_harm_homelessness: "410b5071-6d40-4f5d-982e-43d915420c51",
  immediate_risk_of_harm_neglect: "3c67d313-571c-4aaa-beb5-df54b07ba57f",
  immediate_risk_of_harm_sexual_assault: "a4a720ba-86d3-4b86-b370-53b7e14b8e9a",
  immediate_risk_of_harm_social_services_involvement:
    "acc69776-ed55-4faf-b87a-551caaa31bf0",
  immediate_risk_of_harm_other: "c4bb7752-52c6-4457-a891-79896054889b",
  contact_you_by_phone_to_ensure_your_wellbeing_phone:
    "e7958ede-8942-4f00-b5bc-6eee8e916b44",
  contact_you_by_phone_to_ensure_your_wellbeing_text:
    "cb9e5837-cf15-4e1d-870f-8a0fc85ec0a4",
  contact_you_by_phone_to_ensure_your_wellbeing_email:
    "572e18e4-3917-4762-8c14-56c6980b1119",
  contact_you_by_phone_to_ensure_your_wellbeing_not_safe:
    "466a59c9-0b4f-417b-bdc5-4b0c0bf5f8d1",
  ubrn_number: "d3b8e1af-59f6-4b65-bae1-0f0a1679d6f0",
  passcode: "c1a2e3f4-5678-90ab-cdef-1234567890ab",
  need_to_contact_GP_happy_of_us_to_do_yes:
    "a3f5c1f3-b6f5-4a0e-b67d-d6a9f629a8c7",
  need_to_contact_GP_happy_of_us_to_do_no:
    "e8b30ad3-7d8a-4641-a1f3-03f8be717561",
};

// To get eligibility id
export const getOptionId = (value: string) => {
  return eligibilityOptionsIDs[value];
};

export const getQuestionById = (id: String, data: any) => {
  return data?.QuestionSetIds?.questions?.find((x: any) => x.id === id)
    ?.question;
};
export const getDescriptionById = (id: String, data: any) => {
  return data?.QuestionSetIds?.questions?.find((x: any) => x.id === id)
    ?.description;
};

export const getAnsByQid = (id: String, data?: any) => {
  const questionIndex = data?.QuestionSetIds?.questions?.findIndex(
    (x: any) => x.id === id
  );
  let ans: any = "";
  if (questionIndex > -1) {
    const question = data?.QuestionSetIds?.questions[questionIndex];
    const new_data = JSON.parse(JSON.stringify(data));
    if (
      new_data.QuestionSetIds.questions[questionIndex].userAnswer &&
      new_data.QuestionSetIds.questions[questionIndex].userAnswer.length > 0
    ) {
      if (
        question.type === questionTypes.yesnoquestion ||
        question.type === questionTypes.dropdown
      ) {
        ans = data?.QuestionSetIds?.questions?.find((x: any) => x.id === id)
          ?.userAnswer?.[0]?.optionId;
      } else if (question.type === questionTypes.multiplechoice) {
        ans = data?.QuestionSetIds?.questions?.find(
          (x: any) => x.id === id
        )?.userAnswer;
      } else {
        ans = data?.QuestionSetIds?.questions?.find((x: any) => x.id === id)
          ?.userAnswer?.[0]?.answer;
      }
    }
  }
  return ans;
};

export const checkAnsExitByQid = (id: String, data: any, optionID: string) => {
  const questionIndex = data?.QuestionSetIds?.questions?.findIndex(
    (x: any) => x.id === id
  );
  let ans = [];
  if (questionIndex > -1) {
    const question = data?.QuestionSetIds?.questions[questionIndex];
    const new_data = JSON.parse(JSON.stringify(data));
    if (
      new_data.QuestionSetIds.questions[questionIndex].userAnswer &&
      new_data.QuestionSetIds.questions[questionIndex].userAnswer.length > 0
    ) {
      if (question.type === questionTypes.multiplechoice) {
        ans = data?.QuestionSetIds?.questions?.find(
          (x: any) => x.id === id
        )?.userAnswer;
      }
    }
  }
  if (ans && ans.length) {
    const response = ans.filter((ans: any) => ans.optionId === optionID);
    if (response && response.length) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const setAnswerByQid = (id: String, data: any, value: any) => {
  const questionIndex = data?.QuestionSetIds?.questions?.findIndex(
    (x: any) => x.id === id
  );
  if (questionIndex > -1) {
    const question = data?.QuestionSetIds?.questions[questionIndex];
    const new_data = JSON.parse(JSON.stringify(data));
    if (new_data.QuestionSetIds.questions[questionIndex].userAnswer) {
      if (
        new_data.QuestionSetIds.questions[questionIndex].userAnswer.length > 0
      ) {
        if (
          question.type === questionTypes.yesnoquestion ||
          question.type === questionTypes.dropdown
        ) {
          new_data.QuestionSetIds.questions[
            questionIndex
          ].userAnswer[0].optionId = UUIDv4.validate(value) ? value : null;
        } else if (question.type === questionTypes.multiplechoice) {
          new_data.QuestionSetIds.questions[questionIndex].userAnswer =
            value && value.length > 0 ? value : null;
        } else {
          new_data.QuestionSetIds.questions[
            questionIndex
          ].userAnswer[0].answer = value;
        }
      } else {
        if (
          question.type === questionTypes.yesnoquestion ||
          question.type === questionTypes.dropdown
        ) {
          new_data.QuestionSetIds.questions[questionIndex].userAnswer = [
            { optionId: UUIDv4.validate(value) ? value : null },
          ];
        } else if (question.type === questionTypes.multiplechoice) {
          new_data.QuestionSetIds.questions[questionIndex].userAnswer = [
            ...value,
          ];
        } else {
          new_data.QuestionSetIds.questions[questionIndex].userAnswer = [
            { answer: value },
          ];
        }
      }
    } else {
      if (
        question.type === questionTypes.yesnoquestion ||
        question.type === questionTypes.dropdown
      ) {
        new_data.QuestionSetIds.questions[questionIndex] = {
          userAnswer: [{ optionId: UUIDv4.validate(value) ? value : null }],
          ...new_data.QuestionSetIds.questions[questionIndex],
        };
      } else if (question.type === questionTypes.multiplechoice) {
        new_data.QuestionSetIds.questions[questionIndex].userAnswer =
          value && value.length > 0 ? value : null;
        // {
        //   userAnswer: [...value],
        //   ...new_data.QuestionSetIds.questions[questionIndex],
        // };
      } else {
        new_data.QuestionSetIds.questions[questionIndex] = {
          userAnswer: [{ answer: value }],
          ...new_data.QuestionSetIds.questions[questionIndex],
        };
      }
    }
    return new_data;
  }
};

// To get eligibility id
export const getId = (value: string) => {
  return eligibilityQuestionIDs[value];
};

export const setAnswerbyArray = (data: any, dataArray: any) => {
  if (!data || !dataArray) {
    return;
  }
  let new_data = data;
  dataArray.map((ans: any) => {
    const questionIndex = new_data?.QuestionSetIds?.questions?.findIndex(
      (x: any) => x.id === ans.id
    );
    if (questionIndex > -1) {
      const question = data?.QuestionSetIds?.questions[questionIndex];
      new_data = JSON.parse(JSON.stringify(new_data));
      if (new_data.QuestionSetIds.questions[questionIndex].userAnswer) {
        if (
          new_data.QuestionSetIds.questions[questionIndex].userAnswer.length > 0
        ) {
          if (
            question.type === questionTypes.yesnoquestion ||
            question.type === questionTypes.dropdown
          ) {
            new_data.QuestionSetIds.questions[
              questionIndex
            ].userAnswer[0].optionId = UUIDv4.validate(ans.value)
              ? ans.value
              : null;
          } else if (
            question.type === questionTypes.multiplechoice &&
            ans.value === ""
          ) {
            new_data.QuestionSetIds.questions[questionIndex].userAnswer = [
              { optionId: null },
            ];
          } else {
            new_data.QuestionSetIds.questions[
              questionIndex
            ].userAnswer[0].answer = ans.value;
          }
        } else {
          if (
            question.type === questionTypes.yesnoquestion ||
            question.type === questionTypes.dropdown
          ) {
            new_data.QuestionSetIds.questions[questionIndex].userAnswer = [
              { optionId: UUIDv4.validate(ans.value) ? ans.value : null },
            ];
          } else if (
            question.type === questionTypes.multiplechoice &&
            ans.value === ""
          ) {
            new_data.QuestionSetIds.questions[questionIndex].userAnswer = [
              { optionId: null },
            ];
          } else {
            new_data.QuestionSetIds.questions[questionIndex].userAnswer = [
              { answer: ans.value },
            ];
          }
        }
      } else {
        if (
          question.type === questionTypes.yesnoquestion ||
          question.type === questionTypes.dropdown
        ) {
          new_data.QuestionSetIds.questions[questionIndex] = {
            userAnswer: [
              { optionId: UUIDv4.validate(ans.value) ? ans.value : null },
            ],
            ...new_data.QuestionSetIds.questions[questionIndex],
          };
        } else if (
          question.type === questionTypes.multiplechoice &&
          ans.value === ""
        ) {
          new_data.QuestionSetIds.questions[questionIndex] = {
            userAnswer: [{ optionId: null }],
            ...new_data.QuestionSetIds.questions[questionIndex],
          };
        } else {
          new_data.QuestionSetIds.questions[questionIndex] = {
            userAnswer: [{ answer: ans.value }],
            ...new_data.QuestionSetIds.questions[questionIndex],
          };
        }
      }
    }
  });
  return new_data;
};

export const list_of_name_in_dob = [
  "risk_of_harm",
  "harm_are_you_at_risk_of",
  "risk_of_other_option",
  "explain_more_about_your_risk",
  "immediate_risk_of_harm",
  "explain_more_about_your_immediate_risk",
  "harm_are_you_at_immediate_risk_of",
  "immediate_risk_of_other_option",
  "how_is_it_safest_contact_to_ensure_your_wellbeing",
];

export const VASuserArray = [
  "risk_of_harm",
  "harm_are_you_at_risk_of",
  "risk_of_other_option",
  "explain_more_about_your_risk",
  "immediate_risk_of_harm",
  "harm_are_you_at_immediate_risk_of",
  "immediate_risk_of_other_option",
  "explain_more_about_your_immediate_risk",
  "how_is_it_safest_contact_to_ensure_your_wellbeing",
  "GP_details",
  "practitioner_name",
  "street_name",
  "Town_or_City",
  "postcode",
  "ubrn_number",
  "passcode",
];
